//noinspection CssUnknownTarget
@import '~styles/mixins';

.headerWrapper {
  height: 96px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-header;
  background-color: $black;

  @include b-d(md) {
    height: 80px;
  }
}

.header {
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  background-color: $black;

  .formSearch {
    z-index: 1;
  }

  .inpSearchDesktop {
    width: 200px;

    @include b-d(md) {
      display: none;
    }
  }

  .inpSearchExpandable {
    @include b-up(md) {
      display: none;
    }
  }

  .gridHeaderRight {
    display: grid;
    grid-auto-flow: column;
    column-gap: 24px;
    align-items: center;

    @include b-d(md) {
      display: none;
    }
  }

  .btnWrapper {
    position: relative;
    z-index: $z-index-header + 10;
  }

  .logo {
    width: 276px;
  }

  .textWallet {
    @include b-d(ipad) {
      display: none;
    }
  }

  .btnMenu {
    @include b-up(md) {
      display: none;
    }
  }
}
