//noinspection CssUnknownTarget
@import '~styles/mixins';

.blockassetLogo {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 276px;

  @include b-d(lg) {
    width: 38px;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .textBlockasset {
    margin-left: auto;

    @include b-d(lg) {
      display: none;
    }
  }

  .textMarketplace {
    @include b-d(lg) {
      display: none;
    }
  }
}
