//noinspection CssUnknownTarget
@import '~styles/mixins';

.cardNft {
  @include fix-ios-border-radius;

  background: linear-gradient(
    56.43deg,
    #333333 36.75%,
    #ffffff 379.65%,
    #2b2b2b 740.13%
  );
  padding: 8px;
  border-radius: 20px;
  overflow: hidden;
  display: grid;
  row-gap: 12px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      56.43deg,
      #485dc1 36.75%,
      #ffffff 379.65%,
      #2b2b2b 740.13%
    );
    opacity: 0;
    transition: all 0.25s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .btnBuy {
      background-color: $purple-dark;
    }
  }
}

.imgAspectRatioContainer {
  @include fix-ios-border-radius;

  border-radius: 20px;
  width: 100%;
  padding-top: 100%;
  position: relative;
  align-self: start;
  overflow: hidden;
}

.imgOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 36%;
  background: linear-gradient(
    180deg,
    rgba(13, 13, 13, 0.9) 0%,
    rgba(13, 13, 13, 0) 100%
  );
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.imgOwner {
  @include fix-ios-border-radius;

  border-radius: 50%;
  overflow: hidden;
}

.badgeNftId {
  padding: 6px 10px;
  background-color: rgba(13, 13, 13, 0.4);
  backdrop-filter: blur(8px);
  border-radius: 100px;

  // Mozilla Firefox
  @supports not (backdrop-filter: blur(8px)) {
    background-color: rgba(13, 13, 13, 0.6);
  }
}

.imgNft {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gridAthlete {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  text-transform: uppercase;
  column-gap: 10px;
  position: relative;
  align-items: center;
}

.textAthleteName {
  color: transparent;
  background: linear-gradient(180deg, #8d8d8d 0%, #ffffff 100%);
  //noinspection CssInvalidPropertyValue
  background-clip: text;
}

.imgAthlete {
  @include fix-ios-border-radius;

  border-radius: 100%;
  overflow: hidden;
}

.btnBuy {
  display: grid;
  justify-items: center;
  row-gap: 5px;
  padding: 12px;
  border-radius: 20px;
  background: $gray-800;
  position: relative;
  transition: background-color 0.25s;
}

.textBuyNow {
  color: $gray-500;
}

.textPrice {
  color: transparent;
  background: $gradient-1-primary;
  //noinspection CssInvalidPropertyValue
  background-clip: text;
}
