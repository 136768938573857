//noinspection CssUnknownTarget
@import '~styles/mixins';

.search {
  display: grid;
  margin-top: 72px;

  @include b-d(md) {
    margin-top: 40px;
  }
}

.textSearchQuery {
  color: $gray-500;
}

.textPageHeading {
  text-transform: none;
}

.navContainerOuter {
  margin-top: 20px;

  @include b-d(md) {
    position: relative;
    width: calc(100% + #{$container-padding-x-xs} * 2);
    margin-left: -$container-padding-x-xs;
  }
}

.navScrollContainer {
  @include hideScrollbar;

  width: 100%;
  overflow-x: scroll;
}

.nav {
  @include b-d(md) {
    display: inline-flex;
    margin: 0 $container-padding-x-xs;
  }
}

%blendShadow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: $container-padding-x-xs;

  @include b-up(md) {
    display: none;
  }
}

.blendShadowStart {
  @extend %blendShadow;

  left: 0;
  background: linear-gradient(
    90deg,
    rgba($body-bg, 1) 0%,
    rgba($body-bg, 0) 100%
  );
}

.blendShadowEnd {
  @extend %blendShadow;

  right: 0;
  background: linear-gradient(
    270deg,
    rgba($body-bg, 1) 0%,
    rgba($body-bg, 0) 100%
  );
}

.wrapperItems {
  margin-top: 32px;
}

.paginator {
  margin-top: 40px;
}
