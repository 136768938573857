//noinspection CssUnknownTarget
@import '~styles/mixins';

.profile {
  display: grid;
}

.coverImgContainer {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 140px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.imgCover {
  width: 100%;
  height: 328px;
  object-fit: cover;

  @include b-d(lg) {
    height: 228px;
  }
}

.container {
  margin-top: -152px;
  width: 100%;
  min-width: 0;
}

.gridAccountInfo {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  row-gap: 16px;

  @include b-d(md) {
    grid-auto-flow: row;
    grid-template-columns: auto;
    grid-template-rows: none;
    justify-content: center;
    text-align: center;
  }
}

.imgAvatar {
  @include fix-ios-border-radius;

  border-radius: 50%;
  object-fit: cover;
  border: 8px solid $black;
  box-sizing: content-box;
  margin: -8px 0 0 -8px;

  @include b-d(md) {
    margin: 0;
  }
}

.containerTextName {
  position: relative;
}

.textName {
  text-transform: none;
}

.btnEditProfile {
  position: absolute;
  bottom: -4px;
  transform: translateY(100%);
  left: 0;
  color: $gray-400;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;
  transition: color 0.25s;

  &:hover {
    color: $white;
  }

  @include b-d(md) {
    left: 50%;
    transform: translate(-50%, 100%);
  }
}

.inpCopy {
  width: 190px;
  align-self: end;
  justify-self: end;
  margin-bottom: calc(48px + 8px - 28px);

  @include b-d(md) {
    margin-bottom: 0;
    justify-self: center;
    margin-top: 12px;
  }

  &.extraMargin {
    @include b-d(md) {
      margin-top: calc(12px + 20px);
    }
  }
}

.navContainerOuter {
  margin-top: 40px;

  @include b-d(md) {
    position: relative;
    width: calc(100% + #{$container-padding-x-xs} * 2);
    margin-left: -$container-padding-x-xs;
  }
}

.navScrollContainer {
  @include hideScrollbar;

  width: 100%;
  overflow-x: scroll;
}

.nav {
  @include b-d(md) {
    display: inline-flex;
    margin: 0 $container-padding-x-xs;
  }
}

%blendShadow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: $container-padding-x-xs;

  @include b-up(md) {
    display: none;
  }
}

.blendShadowStart {
  @extend %blendShadow;

  left: 0;
  background: linear-gradient(
    90deg,
    rgba($body-bg, 1) 0%,
    rgba($body-bg, 0) 100%
  );
}

.blendShadowEnd {
  @extend %blendShadow;

  right: 0;
  background: linear-gradient(
    270deg,
    rgba($body-bg, 1) 0%,
    rgba($body-bg, 0) 100%
  );
}

.items {
  margin-top: 32px;
}

.paginator {
  margin-top: 40px;
}
