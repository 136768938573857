.modal {
  &-dialog {
    padding: $modal-dialog-margin;
  }

  &-header {
    color: $white;
    justify-content: space-between;
    border-bottom: 1px solid $white-100;
  }

  &-title {
    @include text-joyride-15;

    display: block;
  }
}
