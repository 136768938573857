//noinspection CssUnknownTarget
@import '~styles/mixins.scss';

.badgeActiveFilter {
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  background-color: $gray-800;
  border-radius: 100px;
  padding: 6px 12px;
  white-space: nowrap;
}

.btnClose {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-100;
  border-radius: 100%;
  color: $white;
  transition: all 0.25s;

  &:hover {
    color: $gray-200;
    background-color: $gray-600;
  }
}
