//noinspection CssUnknownTarget
@import '~styles/mixins.scss';

.dropdownToggle {
  background: linear-gradient(175.43deg, #212121 3.2%, #131313 96.57%);
  color: $gray-300;
  padding: 8px 12px;
  border-radius: 100px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  transition: color 0.25s, border-color 0.25s;
  overflow: hidden;
  white-space: nowrap;

  &::before {
    content: '';
    background: linear-gradient(
      56.43deg,
      #333333 36.75%,
      #ffffff 379.65%,
      #2b2b2b 740.13%
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 100px;
    transition: opacity 0.25s;
  }

  &:hover {
    color: $white;

    &::before {
      opacity: 1;
    }
  }

  &.active {
    border: 1px solid $white;
    background: #191919;
    color: $white;
    padding: 7px 11px;

    .icon {
      transform: rotateX(180deg);
    }
  }

  .text,
  .icon {
    position: relative;
  }

  .icon {
    transition: 0.25s transform;
  }
}
