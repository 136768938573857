//noinspection CssUnknownTarget
@import '~styles/mixins';

.linkAvatar {
  @include fix-ios-border-radius;

  padding: 2px;
  background: linear-gradient(159.57deg, #202024 13.57%, #131314 88.31%);
  border-radius: 50%;

  &.activePage {
    .backgroundDark::after {
      display: block;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background: linear-gradient(
      159.57deg,
      lighten(#202024, 15) 13.57%,
      lighten(#131314, 15) 88.31%
    );
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .backgroundDark {
      &::before {
        opacity: 1;
      }
    }
  }
}

.backgroundDark {
  padding: 2px;
  background: linear-gradient(159.57deg, #202024 13.57%, #131314 88.31%);
  border-radius: 50%;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(
      159.57deg,
      lighten(#202024, 15) 13.57%,
      lighten(#131314, 15) 88.31%
    );
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &::after {
    content: '';
    display: none;
    position: absolute;
    z-index: -1;
    background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border-radius: 50%;
  }
}

.imgAvatar {
  @include fix-ios-border-radius;

  border-radius: 50%;
}
