//noinspection CssUnknownTarget
@import '~styles/mixins.scss';

.input-select-container {
  .input-select {
    &__control {
      display: grid;
      grid-auto-flow: column;
      column-gap: 16px;
      background: $input-bg
        linear-gradient(180deg, #202024 13.57%, #131314 88.31%);
      border-radius: $input-border-radius;
      border: 0;
      padding: 12px $input-padding-x;
      cursor: pointer;

      &:hover {
        .input-select__indicator {
          color: $white !important;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: -1;
        border-radius: calc(#{$input-border-radius} + 1px) !important;
        background: linear-gradient(
          107.36deg,
          #333333 -1.2%,
          #ffffff 38.53%,
          #2b2b2b 80.3%
        );
        opacity: 0;
        transition: opacity 0.25s;
      }

      &--is-focused {
        outline: 0;
        position: relative;
        border: 0;
        box-shadow: none;

        &::before {
          opacity: 1;
        }
      }
    }

    &__value-container {
      padding: 0;
      margin: 0;
      font-size: $input-font-size;
      line-height: $input-line-height;
      font-weight: $input-font-weight;

      > div:not(.input-select__single-value) {
        color: $input-placeholder-color;
      }

      > .input-select__single-value {
        color: $input-color;
      }
    }

    &__input-container {
      padding: 0;
      margin: 0;
    }

    &__indicator {
      padding: 0;
      color: $gray-300;

      &:hover {
        cursor: $gray-300;
      }
    }

    &__menu {
      background-color: $gray-800;
      border-radius: $input-border-radius;
      border: $input-border-width solid $input-border-color;
      cursor: default;
    }

    &__option {
      cursor: pointer;
      transition: color 0.25s;
      padding: 12px 16px;
      color: $gray-300;

      &:hover {
        background-color: transparent;
        color: $white;
      }

      &--is-focused {
        background-color: transparent;
      }

      &--is-selected {
        background-color: transparent;
        color: $cyan;

        &:hover {
          color: $cyan;
        }
      }
    }
  }
}
