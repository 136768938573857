//noinspection CssUnknownTarget
@import '~styles/mixins';

.imageSelectedField {
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  padding: 32.5px;
  background-color: transparent;
  border-radius: 12px;
  align-items: center;
  grid-template-columns: auto 1fr;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23323232' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  .imgPreview {
    width: 68px;
    height: 68px;
    object-fit: cover;
    border-radius: 8px;
    transform: translateZ(0); // Fix iOS Safari border-radius bug
  }

  .colImgInfo {
    display: grid;
    row-gap: 4px;
    justify-items: flex-start;
  }

  .textInfo {
    color: $white;
  }

  .textSize {
    //color: $white-500;
    white-space: nowrap;
  }

  .btnDelete {
    //@include text-nav;

    color: $gray-400;
    transition: color 0.25s;

    &:hover {
      color: $cyan;
    }
  }
}
