.container {
  @extend .container-fluid;

  max-width: 1440px;
  min-width: 0;

  &-fluid {
    width: 100%;
    margin: auto;
    padding: 0 $container-padding-x-xxl;

    @include b-btw(md, xl) {
      padding: 0 $container-padding-x-md;
    }

    @include b-d(md) {
      padding: 0 $container-padding-x-xs;
    }
  }
}
