//noinspection CssUnknownTarget
@import '~styles/mixins';

.footer {
  position: relative;
  padding-top: 48px;
  margin-top: 88px;
  overflow: hidden;

  @include b-d(xl) {
    padding-top: 72px;
  }

  @include b-d(lg) {
    padding-top: 64px;
  }

  @include b-d(md) {
    padding-top: 40px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;

    @include b-up(xl) {
      width: 955px;
      height: 284px;
      bottom: -128px;
      right: calc(50% - 720px - 150px);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 955 284'%3E%3Cg clip-path='url(%23a)' filter='url(%23b)' opacity='.6'%3E%3Cpath stroke='url(%23c)' stroke-width='2' d='M362.263 23.623C496.679-12.393 629.342-3.905 735.157 38.888S919.883 158.747 946.982 259.88c2.86 10.676 5.735 21.231 8.574 31.659 10.668 39.173 20.847 76.552 27.98 111.832 9.03 44.668 13.153 85.865 7.216 122.935-5.931 37.038-21.905 69.981-53.135 98.169-31.252 28.209-77.834 51.702-145.049 69.713-67.276 18.026-114.139 27.013-156.623 29.781-42.477 2.768-80.622-.677-130.478-7.561-16.277-2.248-33.798-4.861-53.118-7.743-99.068-14.778-245.459-36.614-514.197-52.384-35.548-3.752-59.626-15.294-74.176-32.606-14.548-17.311-19.71-40.56-17.068-68.025 5.292-54.99 41.833-126.429 95.833-199.333 53.97-72.866 125.277-147.055 199.9-207.527C217.279 88.304 295.156 41.605 362.263 23.623Z' /%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='c' x1='-161.839' x2='948.116' y1='556.988' y2='259.577' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234B98CD'/%3E%3Cstop offset='1' stop-color='%231FCFB1'/%3E%3C/linearGradient%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h955v284H0z'/%3E%3C/clipPath%3E%3Cfilter id='b' width='1189.29' height='765.892' x='-174.755' y='-11.965' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='8'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_1116:1789'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_1116:1789' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    }
  }

  &_inner {
    color: $gray-300;
    display: grid;
    row-gap: 12px;

    @include b-d(xxl) {
      row-gap: 20px;
    }

    @include b-d(md) {
      text-align: center;
      row-gap: 56px;
    }
  }

  &_top {
    display: grid;
    column-gap: 80px;
    grid-template-columns: auto 1fr auto;

    @include b-d(xxl) {
      column-gap: 56px;
    }

    @include b-d(lg) {
      column-gap: 48px;
    }

    @include b-up(md) {
      align-items: start;
      justify-content: space-between;
      grid-auto-flow: column;

      .navMenu {
        display: grid;
        justify-items: end;

        > .footerBox {
          display: inline-grid;
        }
      }
    }

    @include b-d(md) {
      grid-template-columns: auto;
      justify-items: center;
      row-gap: 56px;

      .navMenu {
        margin-top: -16px;
      }
    }

    .footerBox {
      display: grid;
      row-gap: 24px;
    }
  }

  &_descr {
    max-width: 255px;
    color: $gray-300;
    font-size: 14px;

    @include b-d(md) {
      max-width: 400px;
    }
  }

  &_title {
    color: $white;
    text-transform: uppercase;
  }

  &_form {
    position: relative;
    min-width: 255px;

    @include b-d(lg) {
      min-width: 216px;
    }

    @include b-d(md) {
      min-width: 100%;
    }
  }

  &_contacts {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    row-gap: 24px;

    @include b-d(lg) {
      row-gap: 12px;
    }

    @include b-d(md) {
      justify-items: center;
    }

    &_link {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      align-items: center;
      column-gap: 8px;
      color: $gray-300;
      transition: color 0.25s;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  &_field {
    padding-right: 42px !important;
  }

  &_btn {
    position: absolute;
    z-index: 2;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    padding: 5px;
    transition: color 250ms;

    &:hover,
    &:focus {
      color: $gray-300;
    }

    &:disabled {
      color: $green;
    }
  }

  &_bottom {
    display: grid;
    column-gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: $gray-300;

    @include b-up(md) {
      align-items: center;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    @include b-o(lg) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include b-d(md) {
      padding-top: 0;
      row-gap: 16px;
    }

    span {
      white-space: nowrap;
    }
  }

  &_nav {
    padding: 0;
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: end;
    column-gap: 10px;
    margin: -5px;

    @include b-d(md) {
      order: -1;
      margin: -6px 0;
      justify-content: center;
    }

    &_item {
      position: relative;
      white-space: nowrap;

      &:not(:first-child) {
        &::before {
          content: '';
          width: 1px;
          height: 12px;
          background: $gray-300;
          position: absolute;
          left: -5.5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &_link {
      padding: 5px;
      display: block;
      color: $gray-300;

      &:hover {
        color: $white;
      }

      @include b-d(md) {
        padding: 6px;
      }
    }
  }
}
