//noinspection CssUnknownTarget
@import '~styles/mixins';

.layout {
  min-width: 375px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
  padding-top: 96px;

  @include b-d(md) {
    padding-top: 80px;
  }
}
