//noinspection CssUnknownTarget
@import '~styles/mixins';

.legends {
  padding-top: 72px;
  @include b-d(lg) {
    padding-top: 40px;
  }
}

.items {
  margin: 24px 0 40px;
}
