//noinspection CssUnknownTarget
@import '~styles/mixins';

.social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;

  &.footer {
    column-gap: 16px;

    @include b-d(md) {
      justify-content: center;
      column-gap: 18px;
    }
  }

  &_link {
    display: block;
  }
}
