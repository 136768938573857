//noinspection CssUnknownTarget
@import '~styles/mixins';

.auctionHistoryItem {
  display: grid;
  grid-template-columns: 48px auto;
  grid-template-rows: auto auto;
  column-gap: 12px;
  row-gap: 4px;
}

.imgWrapper {
  grid-row: 1 / 3;
}

.img {
  @include fix-ios-border-radius;

  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.textDesc {
  font-weight: $font-weight-semi-bold;
  color: $gray-400;
  align-self: end;
}

.textDate {
  color: $gray-600;
  align-self: start;
}

.textUserLink {
  color: $white;

  &:hover {
    color: $gray-300;
  }
}

.textPrice {
  color: $white;
  white-space: nowrap;
}
