button {
  display: block;
  outline: none;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  font-feature-settings: inherit;
}

.btn {
  position: relative;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  text-transform: uppercase;
  transition: 0.25s all;

  &::before {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: inherit;
    z-index: -1;
  }

  &-sm {
    @include text-joyride-12;

    line-height: 12px;

    &::before {
      left: -1px;
      top: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
    }
  }

  /* Variant: tertiary */
  &-tertiary {
    @include text-joyride-12;

    line-height: 1;
    padding: 12px 20px;
    color: $gray-300;
    background-color: $black;
    border: 1px solid transparent;

    &::before {
      background: linear-gradient(
        134.72deg,
        #c4c4c4 0%,
        rgba(196, 196, 196, 0) 100%
      );
    }

    &::after {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border-radius: inherit;
      z-index: -1;
      background: linear-gradient(134.72deg, #fff 0%, #fff 100%);
      opacity: 0;
      transition: opacity 0.25s;
    }

    &:hover {
      color: $white;

      &::after {
        opacity: 1;
      }
    }

    &:focus {
      box-shadow: none;
      background-color: #191919;
    }
  }

  /* Variant: neon */
  &-neon {
    box-shadow: 0 0 20px rgba(54, 179, 192, 0.7);
    background-position: -2px 0;
    background-clip: padding-box;

    &:not(:hover):not(:focus) {
      background-image: linear-gradient(
        100.22deg,
        rgba(42, 189, 232, 0.25) 3.4%,
        rgba(42, 189, 232, 0) 45.23%,
        rgba(42, 189, 232, 0) 47.28%,
        rgba(32, 207, 178, 0.24) 108.14%
      );
      background-color: $black;
    }

    &::before {
      background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
    }
  }

  /* Variant: purple */
  &-purple {
    background-position: -2px 0;
    background-clip: padding-box;
    &:not(:hover):not(:focus) {
      background-color: #110616;
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(215 113 230 / 25%);
    }
    &::before {
      background: radial-gradient(
        137.41% 108.7% at 93.79% 18.39%,
        #f97ff4 0%,
        #7b25bf 24.14%,
        #f989f4 41.12%,
        #fce5fb 57.85%
      );
    }
  }

  /* Variant: outline-light */
  &-outline-light {
    &:not(:hover):not(:focus) {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  /* Variant: deep */
  &-deep {
    background: $black;
    background-position: -2px 0;
    background-clip: padding-box;
    width: fit-content;
    padding: 15.5px 36px;
    border-radius: 40px;
    border-width: 2px;
    line-height: 1.43;
    position: relative;

    span {
      position: relative;
      transition: color 250ms ease-in-out;

      &::before {
        transition: opacity 250ms ease-in-out;
        content: attr(data-label);
        position: absolute;
        left: 0;
        top: 0;
        color: transparent;
        //noinspection CssInvalidPropertyValue
        background-clip: text;
        background-image: linear-gradient(
          160deg,
          #333333 -1.2%,
          #ffffff 38.53%,
          #2b2b2b 80.3%
        );
      }
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      left: -2px;
      top: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border-radius: inherit;
      z-index: -1;
      transition: opacity 250ms;
    }

    &::before {
      background: linear-gradient(
        160deg,
        #333333 -1.2%,
        #ffffff 38.53%,
        #2b2b2b 80.3%
      );
    }

    &::after {
      background: #eaeaea;
      opacity: 0;
    }

    &:hover,
    &:focus {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }

      span {
        &:before {
          opacity: 0;
        }
      }
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
    }
  }
}
