//noinspection CssUnknownTarget
@import '~styles/mixins.scss';

.popover {
  display: grid;
  padding: 16px;
  row-gap: 20px;
}

.inputGrid {
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  align-items: center;
  color: $gray-600;
}

.textError {
  color: #c24fbd;
}

.btnApply {
  justify-self: end;
}
