//noinspection CssUnknownTarget
@import '~styles/mixins';

.inpSearchExpandableWrapper {
  position: relative;
  width: 48px;
  height: 48px;
}

.inpSearchExpandable {
  z-index: 1;
  position: absolute;
}
