//noinspection CssUnknownTarget
@import '~styles/mixins';

.userItem {
  display: grid;
  grid-template-columns: 48px auto;
  grid-template-rows: repeat(2, auto);
  column-gap: 12px;
  row-gap: 4px;

  .imgWrapper {
    grid-row: 1 / 3;
  }

  .img {
    @include fix-ios-border-radius;

    border-radius: 50%;
  }

  .textRole {
    color: $gray-600;
    align-self: end;
  }

  .textName {
    align-self: start;
  }
}
