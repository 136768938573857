a {
  transition: color 0.25s;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  caret-color: $cyan;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $cyan;
  border-radius: 5px;

  &:hover {
    background: darken($cyan, 10);
  }
}

::selection {
  background: rgba($cyan, 0.3);
}

svg {
  display: block;
}

.grid-legends {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;

  @include b-d(xxl) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include b-d(lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 20px;
  }

  @include b-d(ipad) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include b-d(md) {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
}

.grid-nfts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  @include b-d(xxl) {
    gap: 24px;
  }

  @include b-btw(lg, xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include b-btw(md, lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include b-d(md) {
    grid-template-columns: 1fr;
  }
}
