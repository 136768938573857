//noinspection CssUnknownTarget
@import '~styles/mixins';

.btnMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-400;
  width: 48px;
  height: 48px;
  background: linear-gradient(159.57deg, #202024 13.57%, #131314 88.31%);
  border-radius: 50%;
  transition: color 0.25s;

  &:hover {
    color: $white;
  }
}
