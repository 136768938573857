//noinspection CssUnknownTarget
@import '~styles/mixins';

.editProfile {
  display: grid;
  justify-items: center;
  margin: 72px auto 0;
  padding: 0 16px;

  > h1 {
    text-align: center;
  }

  @include b-d(md) {
    margin-top: 40px;
  }
}

.form {
  margin-top: 48px;
  display: grid;
  row-gap: 32px;
  max-width: 400px;
  width: 100%;
}

.btnSave {
  margin-top: 8px;
  justify-self: center;
}

.btnDisconnect {
  justify-self: center;
  padding: 8px;
  color: $gray-300;
  text-transform: uppercase;
  transition: 0.25s color;

  &:hover {
    color: $white;
  }
}
