//noinspection CssUnknownTarget
@import '~styles/mixins';

.cardExtraPriceInfo {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 6px 16px 0;
  background: linear-gradient(180deg, rgba(21, 21, 21, 0.6) 0%, #000000 100%);
  border-radius: 12px;
  padding: 12px;
  color: $gray-400;
  column-gap: 24px;

  .textPrimary {
    color: $white;
    white-space: nowrap;
  }

  @include b-d(md) {
    grid-auto-flow: row;
    row-gap: 4px;
  }
}
