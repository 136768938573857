.form {
  &-text {
    display: block;
    &--error {
      text-align: left;
      margin-top: 6px;
      color: $cyan;
    }
  }

  &-label {
    line-height: calc(16 / 13);
    letter-spacing: 0.01em;
  }

  &-control {
    $this: &;

    background: linear-gradient(180deg, #202024 13.57%, #131314 88.31%);
    position: relative;
    border-radius: $input-border-radius !important;

    &--gradient-border {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      z-index: -1;
      border-radius: calc(#{$input-border-radius} + 1px) !important;
      background: linear-gradient(
        107.36deg,
        #333333 -1.2%,
        #ffffff 38.53%,
        #2b2b2b 80.3%
      );
      opacity: 0;
      transition: opacity 0.25s;
    }

    /* Variant: SOL */
    &--sol {
      padding-right: calc(16px + 35px + 16px);
    }

    /* Variant: search */
    &--search {
      padding-left: 48px;
      caret-color: $white;
    }

    /* Variant: search-expandable */
    &--search-expandable {
      caret-color: $white;
      border-radius: 100px !important;
      color: transparent;

      & + #{$this}--gradient-border {
        border-radius: 100px !important;
      }

      &:focus,
      &#{$this}--keep-expanded {
        padding-left: 48px;
        color: $white;
      }
    }

    &:focus {
      box-shadow: none;

      & + #{$this}--gradient-border {
        opacity: 1;
      }
    }
  }
}
