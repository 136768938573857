//noinspection CssUnknownTarget
@import '~styles/mixins';

.cardUser {
  @include fix-ios-border-radius;

  padding: 8px;
  background: linear-gradient(175.43deg, #212121 3.2%, #131313 96.57%);
  border-radius: 24px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      56.43deg,
      #333333 36.75%,
      #ffffff 379.65%,
      #2b2b2b 740.13%
    );
    opacity: 0;
    transition: all 0.25s;
  }

  &:hover {
    color: currentColor;

    &::before {
      opacity: 1;
    }

    .imgAvatarWrapper::before {
      opacity: 1;
    }
  }

  .infoWrapper {
    display: grid;
    justify-items: center;
    row-gap: 16px;
    position: relative;
    margin-top: calc(-96px + 24px);
    padding: 0 8px 12px;
  }

  .imgAspectRatioContainer {
    @include fix-ios-border-radius;

    width: 100%;
    padding-top: 44.44%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 36%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .imgCover {
    @include fix-ios-border-radius;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }

  .imgAvatarWrapper {
    padding: 4px;
    border-radius: 50%;
    background: linear-gradient(175.43deg, #212121 3.2%, #131313 96.57%);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        56.43deg,
        #333333 36.75%,
        #ffffff 379.65%,
        #2b2b2b 740.13%
      );
      border-radius: 50%;
      opacity: 0;
      transition: all 0.25s;
    }
  }

  .imgAvatar {
    @include fix-ios-border-radius;

    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
  }

  .textName {
    position: relative;
  }
}
