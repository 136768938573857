//noinspection CssUnknownTarget
@import '~styles/mixins';

.pageWrapper {
  overflow-x: hidden;
}

.homepage {
  width: 100%;
  display: grid;

  > section {
    width: 100%;
    min-width: 0;
    margin-top: 80px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.rowHeading {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sectionLegends {
  .outerContainer {
    @include b-d(md) {
      position: relative;
      width: calc(100% + #{$container-padding-x-xs} * 2);
      margin-left: -$container-padding-x-xs;
    }
  }

  .scrollContainerLegends {
    @include b-d(md) {
      @include hideScrollbar;

      overflow-x: scroll;
    }
  }

  %blendShadowLegends {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $container-padding-x-xs;

    @include b-up(md) {
      display: none;
    }
  }

  .blendShadowLegendsStart {
    @extend %blendShadowLegends;

    left: 0;
    background: linear-gradient(
      90deg,
      rgba($body-bg, 1) 0%,
      rgba($body-bg, 0) 100%
    );
  }

  .blendShadowLegendsEnd {
    @extend %blendShadowLegends;

    right: 0;
    background: linear-gradient(
      270deg,
      rgba($body-bg, 1) 0%,
      rgba($body-bg, 0) 100%
    );
  }

  .rowLegends {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: repeat(5, 1fr);

    @include b-btw(lg, xxl) {
      grid-template-columns: repeat(4, 1fr);

      & > a:nth-child(5) {
        display: none;
      }
    }

    @include b-btw(md, lg) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;

      & > a {
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }

    @include b-btw(md, ipad) {
      column-gap: 16px;
    }

    @include b-d(md) {
      display: inline-grid;
      grid-template-columns: repeat(5, 224px);
      padding: 0 $container-padding-x-xs;
    }
  }

  .btnShowAllDesktop {
    @include b-d(md) {
      display: none;
    }
  }

  .btnShowAllMobile {
    margin: 20px auto 0;

    @include b-up(md) {
      display: none;
    }
  }
}

.sectionExplore {
  .rowHeading {
    position: relative;

    @include b-d(md) {
      grid-auto-flow: row;
      justify-content: stretch;
      row-gap: 12px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 456px;
      height: 478px;
      background-image: url('./assets/hompage_bg_1.png');
      background-size: cover;
      top: 120%;
      left: -11%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @include b-d(xxl) {
        display: none;
      }
    }
  }

  .inpSelectSort {
    min-width: 180px;
  }

  .outerWrapperFilters {
    @include extend-to-edges-outer;

    position: relative;
  }

  %blendShadowFilters {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $container-padding-x-xxl;

    @include b-btw(md, xl) {
      width: $container-padding-x-md;
    }
    @include b-d(md) {
      width: $container-padding-x-xs;
    }
  }

  .blendShadowFiltersStart {
    @extend %blendShadowFilters;

    @include b-up(xxl) {
      /* Hidden due to background image interference */
      display: none;
    }

    left: 0;
    background: linear-gradient(
      90deg,
      rgba($body-bg, 1) 0%,
      rgba($body-bg, 0) 100%
    );
  }

  .blendShadowFiltersEnd {
    @extend %blendShadowFilters;

    right: 0;
    background: linear-gradient(
      270deg,
      rgba($body-bg, 1) 0%,
      rgba($body-bg, 0) 100%
    );
  }

  .scrollContainerFilters {
    @include hideScrollbar;

    width: 100%;
    overflow-x: scroll;
  }

  .rowExploreFilters {
    @include extend-to-edges-inner;

    display: inline-flex;
    margin-left: -6px;

    > button {
      margin-left: 6px;
    }
  }

  .rowActiveFilters {
    @include extend-to-edges-inner;

    display: inline-flex;
    margin-top: 16px;
    margin-left: -4px;

    > div {
      margin-left: 4px;
    }
  }

  .gridExplore {
    position: relative;
    margin-top: 32px;

    &::after {
      content: '';
      background-image: url('./assets/hompage_bg_2.png');
      background-size: cover;
      position: absolute;
      width: 1923px;
      height: 272px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @include b-d(xxl) {
        display: none;
      }
    }
  }

  .paginator {
    margin-top: 40px;
  }
}
