//noinspection CssUnknownTarget
@import '~styles/mixins';

.blockNft {
  display: grid;
  grid-template-columns: 600px 1fr;
  align-items: start;
  column-gap: 60px;
  margin-top: 60px;

  @include b-btw(xl, xxl) {
    grid-template-columns: 480px 1fr;
  }

  @include b-btw(lg, xl) {
    grid-template-columns: 440px 1fr;
    margin-top: 24px;
  }

  @include b-d(lg) {
    grid-template-columns: 1fr;
    row-gap: 32px;
    margin-top: 24px;
  }

  /* Variant: homepage */
  &.homepage {
    align-items: center;

    .gridPurchase {
      margin-top: 32px;

      @include b-up(lg) {
        grid-auto-flow: row;
        row-gap: 32px;
      }
    }

    .gridAthlete {
      margin-top: 0 !important;
    }
  }
}

.imgAspectRatioContainer {
  @include fix-ios-border-radius;

  padding-top: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.gridAthlete {
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;

  @include b-up(lg) {
    margin-top: 24px;
  }

  .imgAthlete {
    @include fix-ios-border-radius;

    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
  }

  .textNameAthlete {
    @include text-gradient-gray;

    text-transform: uppercase;
  }
}

.title {
  margin-top: 12px;
}

.gridPurchase {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @include b-d(md) {
    grid-auto-flow: row;
    row-gap: 20px;
    grid-template-columns: 1fr;
  }

  .gridPrices {
    display: grid;
    row-gap: 4px;
  }

  .textPricePrimary {
    @include text-gradient-primary;
  }

  .textPriceSecondary {
    color: $gray-600;
  }

  .btnBuyNow {
    @include b-d(md) {
      width: 100%;
    }
  }
}

.tabsBlock {
  margin-top: 32px;

  @include b-d(md) {
    margin-top: 95px;
  }
}

.badgeSold {
  text-transform: uppercase;
  border-radius: 100px;
  color: $gray-300;
  background: linear-gradient(
    159.57deg,
    rgba(30, 30, 30, 0.65) 13.57%,
    #000000 88.31%
  );
  padding: 17px 32px;
  text-align: center;
}
