//noinspection CssUnknownTarget
@import '~styles/mixins';

.navMenuMobile {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
  z-index: $zindex-fixed;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s;

  .container {
    height: 100%;
    padding-top: 35px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
  }

  &.visible {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}

.listLinks {
  display: grid;
  justify-items: center;
  row-gap: 12px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.navLink {
  display: block;
  min-width: 140px;
  padding: 8px 0;
  text-transform: uppercase;
}

.menuFooter {
  display: grid;
  justify-content: stretch;
  margin-top: auto;
  row-gap: 24px;
  text-align: center;

  hr {
    height: 1.5px !important;
    color: $white-100;
  }
}

.btnFooter {
  display: block;
  margin-top: 16px;
}
