//noinspection CssUnknownTarget
@import '~styles/mixins';

.cardAthlete {
  @include fix-ios-border-radius;

  display: grid;
  justify-items: center;
  text-align: center;
  padding: 20px 0;
  background: linear-gradient(175.43deg, #212121 3.2%, #131313 96.57%);
  border-radius: 16px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      56.43deg,
      #333333 36.75%,
      #ffffff 379.65%,
      #2b2b2b 740.13%
    );
    opacity: 0;
    transition: all 0.25s;
  }

  &:hover {
    color: currentColor;

    &::before {
      opacity: 1;
    }
  }

  .imgContainerWrapper {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 130px;
  }

  .imgAspectRatioContainer {
    display: block;
    width: 100%;
    padding-top: 100%;
    position: relative;
  }

  .img {
    @include fix-ios-border-radius;

    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .textName {
    margin-top: 20px;
    position: relative;
  }

  .textNftCount {
    margin-top: 4px;
    justify-self: stretch;
    color: transparent;
    background: radial-gradient(
      98.54% 76.07% at 93.79% 17.7%,
      #f196d1 0%,
      #cf97ec 25%,
      #99bded 50%,
      #9cecca 100%
    );
    //noinspection CssInvalidPropertyValue
    background-clip: text;
    position: relative;
  }
}

.rowLegends {
  display: inline-flex;
}
