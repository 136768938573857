//noinspection CssUnknownTarget
@import '~styles/mixins.scss';

.textDescription {
  color: $gray-400;
}

.tabDetailsContent {
  display: grid;
  row-gap: 32px;
  margin-top: 24px;
}

.tabHistoryContent {
  @include dark-scrollbar;

  max-height: calc(48px * 4 + 24px * 3);
  overflow-y: auto;
  margin-top: 24px;

  &.isLoading {
    overflow-y: hidden;
  }
}

.cardCollection {
  width: 216px;
}

.gridRelatedUsers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: start;

  @include b-d(md) {
    grid-template-columns: auto;
    row-gap: 24px;
  }
}

.gridHistoryItems {
  display: grid;
  row-gap: 24px;
}

.spinner {
  width: 48px;
  height: 48px;
  border-right-color: transparent;
  border-width: 4px;
}
