//noinspection CssUnknownTarget
@import '~styles/mixins';

.dropzone {
  border-radius: 12px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23323232' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  min-height: 92px;
  padding: 32px;
  display: grid;
  align-content: center;
  justify-items: center;
  row-gap: 16px;
  text-align: center;
  cursor: pointer;
  transition: background 0.25s ease-in-out;

  &:hover {
    background-color: $gray-900;
  }

  &.dragActive {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23888888' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: $gray-900;
  }
}

.textMain {
  color: $gray-400;
}

.btnWrapper {
  position: relative;
  z-index: 0;
}
