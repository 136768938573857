//noinspection CssUnknownTarget
@import '~styles/mixins';

.legend {
  padding-top: 72px;
  background-image: url(./img/grid.png);
  background-position: top -60px center;
  background-repeat: no-repeat;
  @include b-d(md) {
    padding-top: 40px;
  }
  &Hero {
    margin-bottom: 56px;
    text-align: center;
    display: grid;
    justify-items: center;
    row-gap: 32px;
  }
  &Photo {
    width: 100%;
    max-width: 256px;
    overflow: hidden;
    border-radius: 50%;
    transform: translateZ(0);
    &Wrapper {
      padding-bottom: 100%;
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &Info {
    display: grid;
    row-gap: 16px;
    justify-items: center;
    p {
      max-width: 400px;
    }
  }
}

.items {
  margin-top: 32px;
}
