//noinspection CssUnknownTarget
@import '~styles/mixins';

.mintNft {
  display: grid;
  width: 100%;
  max-width: calc(400px + 16px * 2);
  margin: 72px auto 0;
  padding: 0 16px;

  > h1 {
    text-align: center;
  }

  @include b-d(md) {
    margin-top: 40px;
  }
}

.nav {
  margin-top: 20px;
  justify-self: center;
}

.tabContent {
  margin-top: 48px;
}
