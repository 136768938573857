.nav {
  $this: &;

  display: grid;

  &--header {
    grid-auto-flow: column;
    margin-right: -16px;
    column-gap: 12px;

    @include b-d(xxl) {
      display: none;
    }
    .nav-link {
      text-transform: uppercase;
    }
  }

  &--dropdown {
    row-gap: 8px;
    justify-items: center;
  }

  &--footer {
    display: grid;
    margin: -6px;

    @include b-up(md) {
      row-gap: 4px;
    }

    .nav-link {
      font-size: inherit;
      line-height: inherit;
      padding: 6px;
      color: $gray-300;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  &-link {
    line-height: 1.25;
  }

  /* Variant: white */
  &-white {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 4px;
    background-color: transparent;
    border: 2px solid $gray-800;
    border-radius: 100px;
    flex-wrap: nowrap;
    padding: 2px;

    .nav-link {
      @include text-syne-14-bold;

      border-radius: 100px;
      display: grid;
      grid-auto-flow: column;
      align-items: flex-end;
      column-gap: 6px;
      padding: 12px 24px;
      position: relative;
      min-width: 104px;
      text-align: center;
      color: $gray-400;
      white-space: nowrap;

      &:hover {
        color: $white;
      }

      &.active {
        color: $black;
        background-color: $white;
      }
      small {
        color: $gray-600;
      }
    }
  }
}
