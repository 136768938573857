.input-group {
  $this: &;

  border-radius: $input-border-radius;
  position: relative;

  /* Variant: SOL */
  &--sol {
    &::after {
      @include text-syne-16-semi-bold;

      content: 'SOL';
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      z-index: 20;
      color: $gray-300;
      user-select: none;
      pointer-events: none;
    }
  }

  /* Variant: search */
  &--search {
    &::after {
      @include text-syne-16-semi-bold;

      display: block;
      width: 20px;
      height: 20px;
      background: url("data:image/svg+xml;utf8,<svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='9.17' cy='9.17' r='5.83' stroke='%23646464' stroke-width='1.5' stroke-linecap='round'/><path d='m16.67 16.67-3.34-3.34' stroke='%23646464' stroke-width='1.5' stroke-linecap='round'/></svg>");
      content: '';
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      z-index: 20;
      user-select: none;
      pointer-events: none;
    }
  }

  /* Variant: search-expandable */
  &--search-expandable {
    width: 48px;
    height: 48px;
    transition: width 0.25s;

    &::after {
      @include text-syne-16-semi-bold;

      display: block;
      width: 24px;
      height: 24px;
      background: url("data:image/svg+xml;utf8,<svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='11' cy='11' stroke='%239D9FA5' stroke-width='1.5' stroke-linecap='round' r='7'/><path d='m20 20-4-4' stroke='%239D9FA5' stroke-width='1.5' stroke-linecap='round'/></svg>");
      content: '';
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      z-index: 20;
      user-select: none;
      pointer-events: none;
    }

    &:focus-within,
    &#{$this}--keep-expanded {
      width: calc(100vw - #{$container-padding-x-xs} * 2 - 48px - 20px);
    }
  }
}
