//noinspection CssUnknownTarget
@import '~styles/mixins';

.paginator {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  list-style-type: none;
  margin: 0;
  padding: 10px;
  user-select: none;

  .pageLink {
    font-weight: $font-weight-semi-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    border: 1.5px solid $gray-800;
    color: $gray-300;
    transition: color 0.25s, border-color 0.25s;

    &:hover {
      border-color: $white;
      color: $white;
    }

    @include b-d(md) {
      width: 36px;
      height: 36px;
    }
  }

  .pageBreak {
    display: none;
  }

  .disabledLink {
    display: none;
  }

  .activeLink {
    background: linear-gradient(
      100.22deg,
      rgba(42, 189, 232, 0.25) 3.4%,
      rgba(42, 189, 232, 0) 45.23%,
      rgba(42, 189, 232, 0) 47.28%,
      rgba(32, 207, 178, 0.24) 108.14%
    );
    position: relative;
    border-width: 0;
    color: $white;

    &::after {
      content: '';
      background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1.5px;
      border-radius: 12px;
      mask: linear-gradient(#fff 0, #fff 100%) content-box,
        linear-gradient(#fff 0, #fff 100%) border-box;
      //-webkit-mask-composite: destination-out; // Breaks on iOS
      mask-composite: exclude;
    }

    &:hover {
      border-width: 0;
    }
  }

  .previousLink {
    margin-right: 16px;

    @include b-d(md) {
      margin-right: 8px;
    }
  }

  .nextLink {
    margin-left: 16px;

    @include b-d(md) {
      margin-left: 8px;
    }
  }
}
