//noinspection CssUnknownTarget
@import '~styles/mixins';

.personalSocialLinks {
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    display: block;
    padding: 12px;
    background-color: $gray-800;
    border-radius: 12px;
  }
}
