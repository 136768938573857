//noinspection CssUnknownTarget
@import '~styles/mixins.scss';

.popover {
  min-width: 180px;
  overflow: hidden;

  .popoverInner {
    @include dark-scrollbar;

    overflow-y: auto;
    width: 100%;
    max-height: 400px;
  }

  .listOptions {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    color: $white;
    overflow-y: auto;
  }

  .itemOption {
    transition: color 0.25s;

    &.itemActive {
      color: $pink;
    }
  }

  .itemOptionButton {
    font-weight: $font-weight-semi-bold;
    color: inherit;
    width: 100%;
    text-align: left;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    align-items: center;
    padding: 10px 15px;

    &:hover,
    &:focus {
      background-color: $gray-800;
    }

    &.active {
      color: $cyan;

      .textQuantity {
        color: $cyan-600;
      }
    }
  }

  .textQuantity {
    color: $gray-500;
  }
}
